import Tabs from '../components/Tabs';
import $wrap from '../base/jqWrap';
import TaskRunner from '../base/TaskRunner';

TaskRunner.add(() => {
    if (typeof $ !== 'undefined') {
        $.fn.tabs = function (...params) {
            return $wrap(this, Tabs, 'tabs', params);
        };
    }

    window.Tabs = Tabs;

    document.querySelectorAll('.tabs').forEach(tabs => {
        new Tabs(tabs);
    });
});