import TaskRunner from '../base/TaskRunner';
import sliders from '../data/swiper';
import { zeroPad } from '../base/util';

const fractionNumFunc = (num) => {
    return zeroPad(num, 2);
};

const defaults = {
    pagination: {
        el: '.swiper-pagination',
        clickable: true
    },
    paginationFraction: {
        el: '.swiper-pagination',
        type: 'fraction',
        formatFractionCurrent: fractionNumFunc,
        formatFractionTotal: fractionNumFunc
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    }
};

const getPreloaderTemplate = () => {
    let preloader = document.createElement('div');
    preloader.classList.add('swiper-lazy-preloader', 'swiper-lazy-preloader-black');
    return preloader;
};


/**
 * Обёртка для слайдеров.
 * @param {DOM Object} elem Объект, который нужно обернуть и проинициализировать.
 */
export const wrapSlider = (elem, settings = undefined) => {
    let sliderIdentifier = elem.getAttribute('data-swiper');
    settings = settings || sliders[sliderIdentifier];

    if (typeof settings === 'object') {
        settings.el = undefined;
        let settObj = Object.assign({}, settings);

        elem.classList.add('swiper-root');

        let swiperCont = document.createElement('div');
        swiperCont.classList.add('swiper-container');
        let swiperWrap = document.createElement('div');
        swiperWrap.classList.add('swiper-wrapper');

        while (elem.children.length > 0) {
            let slide = elem.children[0];
            slide.classList.add('swiper-slide');
            swiperWrap.append(slide);
        }

        swiperCont.append(swiperWrap);
        elem.append(swiperCont);

        switch (settObj.pagination) {
            case 'default':
                // Отрисуем пагинацию, а шо делать
                settObj.pagination = Object.assign({}, defaults.pagination);

                let pagination = document.createElement('div');
                pagination.classList.add(settObj.pagination.el.replace(/\./g, ''));

                elem.append(pagination);

                settObj.pagination.el = pagination;
                break;
            case 'fraction':
                // Отрисуем пагинацию, а шо делать
                settObj.pagination = Object.assign({}, defaults.paginationFraction);

                let paginationFraction = document.createElement('div');
                paginationFraction.classList.add(settObj.pagination.el.replace(/\./g, ''));

                elem.append(paginationFraction);

                settObj.pagination.el = paginationFraction;
                break;
        }

        if (settObj.navigation === 'default') {
            // Отрисуем навигацию, а шо делать
            settObj.navigation = Object.assign({}, defaults.navigation);

            let navigationPrev = document.createElement('button');
            navigationPrev.setAttribute('type', 'button');
            navigationPrev.classList.add(settObj.navigation.prevEl.replace(/\./g, ''));

            let navigationNext = document.createElement('button');
            navigationNext.setAttribute('type', 'button');
            navigationNext.classList.add(settObj.navigation.nextEl.replace(/\./g, ''));

            elem.append(navigationPrev, navigationNext);

            settObj.navigation.prevEl = navigationPrev;
            settObj.navigation.nextEl = navigationNext;
        }

        if (settObj.lazy) {
            // Если у нас врублен лезилодинг
            swiperWrap.querySelectorAll('[data-src],[data-background]').forEach(lazyElem => {
                lazyElem.classList.add('swiper-lazy');
                lazyElem.after(getPreloaderTemplate());
            });
        }

        if (settObj.thumbs != undefined) {
            // Инициализируем слайдер с тумбами
            let thumbSlider = wrapSlider(document.querySelector(settObj.thumbs.swiper.el), settObj.thumbs.swiper);

            if (thumbSlider !== null) {
                settObj.thumbs.swiper = thumbSlider;
            } else {
                settObj.thumbs = undefined;
            }
        }

        let autoplay = Number(elem.getAttribute('data-autoplay'));
        if (autoplay > 0) {
            settObj.autoplay = {
                delay: autoplay
            };
        }

        // Пришло время инициализировать слайдер
        let slider = new Swiper(swiperCont, settObj);

        if (settObj.linkWith) {
            // Связываем слайдеры
            slider.on('slideChange', function () {
                try {
                    let linkedSlider = document.querySelector(`${slider.params.linkWith} .swiper-container`).swiper;

                    if (linkedSlider != undefined) {
                        linkedSlider.slideTo(slider.realIndex);
                    }
                } catch (e) {
                    console.warn(e.message);
                }
            });
        }

        return slider;
    } else {
        console.warn('slider with id ' + sliderIdentifier + ' is not found');
    }

    return null;
};

export const initSliders = () => {
    let foundSliders = document.querySelectorAll('[data-swiper]:not(.swiper-root)');

    foundSliders.forEach(slider => {
        wrapSlider(slider);
    });
};

TaskRunner.add(() => {
    if (typeof Swiper === 'undefined') return;

    initSliders();
});