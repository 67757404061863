export default {
    sliderItems: {
        watchOverflow: true,
        slidesPerView: 'auto',
        loop: false,
        preloadImages: false,
        lazy: {
            loadPrevNext: true,
            loadPrevNextAmount: 3
        }
    },
    featuresNav: {
        effect: 'fade',
        linkWith: '#featuresText',
        slideToClickedSlide: true,
        on: {
            init: (swiper) => {
                const circleRadius = 528 / 2;
                const slidesCount = swiper.slides.length;

                const atomAngle = (2 * Math.PI) / slidesCount;
                const startAngle = -Math.PI / 2;
                const halfAngle = Math.PI + startAngle;
                const bottomTextPosAfter = circleRadius * 2 * .7;

                swiper.slides.forEach((slide, ind) => {
                    const angle = atomAngle * ind + startAngle;

                    const x = Math.cos(angle) * circleRadius + circleRadius + 1;
                    const y = Math.sin(angle) * circleRadius + circleRadius + 1;

                    slide.style.top = `${y}px`;
                    slide.style.left = `${x}px`;

                    slide.classList.toggle('more_half', angle > halfAngle);
                    slide.classList.toggle('bottom_text', y > bottomTextPosAfter);
                });
            },
            slideChange: (swiper) => {
                const dashOffset = 1662;
                const svg = document.getElementById('featuresFigure');
                const currIndex = swiper.activeIndex;
                const atomOffset = dashOffset/swiper.slides.length;

                svg.style.strokeDashoffset = dashOffset - atomOffset*currIndex;
            }
        }
    },
    featuresText: {
        effect: 'fade',
        linkWith: '#featuresNav',
        navigation: 'default',
        pagination: 'fraction',
        autoHeight: true
    },
    tabsNavSlider: {
        freeMode: true,
        slidesPerView: 'auto',
        watchOverflow: true
    },
    sliderRates: {
        watchOverflow: true,
        slidesPerView: 'auto',
        loop: false,
        breakpoints: {
            1200: {
                enabled: false
            }
        }
    },
    facilitySlider:{
        loop: false,
        navigation: 'default',
        pagination: 'fraction',
        slidesPerView: 'auto',
        watchOverflow: true,
        lazy: {
            loadPrevNext: true,
            loadPrevNextAmount: 3
        }
    }
};