const animItems = [
    // Главная
    {
        selector: '.block_hero',
        once: true,
        targets: [
            {
                selector: '.block_hero--text-bg',
                delayBase: .5
            },
            {
                selector: 'h1',
                anim: 'anim-fadeInZoomOut',
                delayBase: 1
            },
            {
                selector: '.block_hero--form',
                delayBase: 1.3
            }
        ]
    },
    {
        selector: '.page_main--block_rate .block_default--header, .page_main--block_connect .block_default--header',
        once: true,
        targets: [
            {
                selector: 'h1, h2, .block_text',
                anim: 'animate__fadeIn',
                delayBase: .3,
                delayStep: .2
            }
        ]
    },
    {
        selector: '.page_main--block_rate .block_default--content, .page_main--block_connect .block_default--content',
        once: true,
        targets: [
            {
                selector: '.form_default,.page_main--block_connect-slider',
                delayBase: .3
            }
        ]
    },
    {
        selector: '.block_img',
        once: true,
        targets: [
            {
                selector: '.block_img--img',
                delayBase: .3
            },
            {
                selector: '.block_default--header > *',
                delayBase: .5,
                delayStep: .2,
                anim: 'animate__fadeIn'
            }
        ]
    },
    {
        selector: '.page_main--block_features',
        once: true,
        targets: [
            {
                selector: '.wrapper',
                delayBase: .3
            }
        ]
    },
    {
        selector: '.page_main--block_map,.page_rates--block_form',
        once: true,
        targets: [
            {
                selector: '.page_main--block_map-head, .block_form',
                delayBase: .3,
                delayStep: .2
            }
        ]
    },
    // Остальные страницы
    {
        selector: '.block_head',
        once: true,
        targets: [
            {
                selector: '.block_head--img',
                delayBase: .5
            },
            {
                selector: '.block_head--text',
                delayBase: .3
            }
        ]
    },
    {
        selector: '.page_rates--block_rates .block_default--header, .page_rates--block_rates .tabs-nav',
        once: true,
        targets: [
            {
                selector: 'h2, .block_text > *,.swiper-container',
                delayBase: .3,
                delayStep: .1
            }
        ]
    },
    {
        selector: '.page_rates--block_rates-slider-item,.page_rates--block_rates-slider-item_alt,.page_rates--block_connect-steps-item,.block_news .block_default--header,.block_news .block_default--foot,.block_news--slider-item,.page_rates--block_facility-slider-item',
        once: true
    },
    {
        selector: '.page_rates--block_features .block_default--header, .page_rates--block_features-list',
        once: true,
        targets: [
            {
                selector: 'h2,.block_text',
                delayBase: .3,
                delayStep: .2
            },
            {
                selector: 'li',
                anim: 'anim-fadeInUp',
                delayBase: .3,
                delayStep: .1
            }
        ]
    },
    {
        selector: '.page_rates--block_order',
        once: true,
        targets: [
            {
                selector: '.page_rates--block_order-bg',
                delayBase: .3
            },
            {
                selector: '.page_rates--block_order-text',
                delayBase: .5,
                anim: 'anim-fadeInUp'
            }
        ]
    },
    {
        selector: '.page_rates--block_connect .block_default--header',
        once: true,
        targets: [
            {
                selector: 'h2, .block_text',
                delayBase: .3,
                delayStep: .1
            }
        ]
    }
];

export default animItems;