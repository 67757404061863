/**
 * Модуль аккордиона
 */

const defaults = {
    selfClose: true,                    // Позволять закрыть текущий элемент (нужно сочитать с multiple)
    multiple: true,                     // Если false то нельзя развернуть больше 1 аккордиона
    collapseChilds: false,              // Сворачивать ли детей при свертывании родителя
};

const ACCORDION_EXPANDED = 'AccordionExpanded';
const ACCORDION_COLLAPSED = 'AccordionCollapsed';

export default class Accordion {
    constructor(elem, options = defaults) {
        this.opts = Object.assign({}, defaults, options);

        this.elem = elem;
        this.content = elem.querySelector('.accordion-content');

        elem.classList.add('accordion-root');
        elem.accordion = this;

        this.isExpand = elem.classList.contains('expanded');

        if (this.isExpand) {
            elem.style.maxHeight = this.content.scrollHeight;
        }
    }

    static get ACCORDION_EXPANDED() {
        return ACCORDION_EXPANDED;
    }

    static get ACCORDION_COLLAPSED() {
        return ACCORDION_COLLAPSED;
    }

    get options() {
        return this.opts;
    }

    get element() {
        return this.elem;
    }

    toggle() {
        if (this.isExpand)
            this.collapse();
        else
            this.expand();
    }

    expand() {
        if (!this.options.multiple) {
            let currExpandedElem = this.elem.parentElement.querySelector('.accordion-root.expanded');

            if (currExpandedElem && currExpandedElem.accordion != undefined) {
                currExpandedElem.accordion.collapse(true);
            }
        }

        this.content.style.maxHeight = `${this.content.scrollHeight}px`;
        this.elem.classList.add('expanded');
        this.isExpand = true;

        let par = this.elem.closest('.accordion-content');

        if (par) {
            par.style.maxHeight = `${par.clientHeight + this.content.scrollHeight}px`;
        }

        this.elem.dispatchEvent(new Event(ACCORDION_EXPANDED));
    }

    collapse(force = false) {
        if (this.options.selfClose || force) {
            this.elem.classList.remove('expanded');
            this.isExpand = false;

            if (this.options.collapseChilds) {
                this.elem.querySelectorAll('.accordion-root.expanded').forEach(elem => {
                    if(elem.accordion)
                        elem.accordion.collapse(true);
                });
            }

            this.elem.dispatchEvent(new Event(ACCORDION_COLLAPSED));
        }
    }

    destroy(){
        this.elem.accordion = null;
        this.elem = null;
        this.opts = null;
        this.content = null;
    }
}