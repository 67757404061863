import * as util from '../base/util';
import TaskRunner from '../base/TaskRunner';

TaskRunner.add(() => {
    let classList = util.rootElem().classList;

    if (util.isTouch()) classList.add('touch-device');
    if (util.isIe()) classList.add('is-ie');
    if (util.isSafari()) classList.add('is-safari');

    const getDocumentLength = () => {
        return Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
    };

    let windowHeight;
    let documentHeight;

    const recalcParams = () => {
        windowHeight = window.innerHeight;
        documentHeight = getDocumentLength() - windowHeight;
        document.documentElement.style.setProperty('--screensTotal', documentHeight/windowHeight);
    };

    let prevScroll;

    const tick = () => {
        prevScroll = window.scrollY;

        const pageProgress = prevScroll / documentHeight;
        const pageProgressRelative = prevScroll / windowHeight;

        document.documentElement.style.setProperty('--pageProgress', pageProgress);
        document.documentElement.style.setProperty('--screensScrolled', pageProgressRelative);
    }

    setTimeout(() => {
        recalcParams();
        tick();
    }, 200);

    window.addEventListener('scroll', (e) => {
        if (prevScroll === window.scrollY) return;

        tick();
    });

    window.addEventListener('resize', (e) => {
        recalcParams();
        tick();
    });

    document.body.classList.remove('preload');
});