import * as util from '../base/util';
import TaskRunner from '../base/TaskRunner';
import LiveEvent from '../base/LiveEvent';

TaskRunner.add(() => {
    // Кастомный vh
    util.refreshCustomVH();

    window.addEventListener('resize', () => {
        util.refreshCustomVH();
    });

    document.documentElement.style.setProperty('--scrollbarWidth', util.getScrollbarWidth() + 'px');

    LiveEvent
        .on('click', '#pageScrollUp', (e) => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        })/* 
        .on('click', '.countpicker-dec', (e, targ) => {
            const fld = targ.nextElementSibling;

            if(--fld.value === 1){
                targ.disabled = true;
            }
        })
        .on('click', '.countpicker-inc', (e, targ) => {
            const fld = targ.previousElementSibling;

            if(++fld.value === 2){
                fld.previousElementSibling.disabled = false;
            }
        })
        .on('change', '.countpicker-num', (e, targ) => {
            if(targ.value <= 1){
                targ.value = 1;
                targ.previousElementSibling.disabled = true;
            }else{
                targ.previousElementSibling.disabled = false;
            }
        }) */;
});