/**
 * Модуль табов
 */

const defaults = {

};

export default class Tabs {
    constructor(rootElement, options = defaults) {
        this.opts = Object.assign({}, defaults, options);

        this.root = rootElement;
        this.root.classList.add('tabs-root', 'tabs-initialized');

        this.nav = this.root.querySelector('.tabs-nav');
        this.container = this.root.querySelector('.tabs-container');

        const hash = window.location.hash.replace('#','');
        let activeInd = 1;

        this.nav.querySelectorAll('.tabs-nav-item').forEach((navElem, ind) => {
            if(hash.length > 0 && navElem.id === hash){
                activeInd = ind + 1;
            }

            navElem.addEventListener('click', (e) => {
                this.expandTab(ind + 1);
            });
        });

        this.root.tabs = this;

        this.expandTab(activeInd);
    }

    expandTab(ind) {
        try {
            let currActiveNav = this.nav.querySelector('.tabs-active');

            if(currActiveNav){
                currActiveNav.classList.remove('tabs-active');
            }
            
            this.nav.querySelector(`.tabs-nav-item:nth-child(${ind})`).classList.add('tabs-active');

            let currActiveTab = this.container.querySelector('.tabs-active');

            if(currActiveTab){
                currActiveTab.classList.remove('tabs-active');
            }
            
            this.container.querySelector(`.tabs-tab:nth-child(${ind})`).classList.add('tabs-active');
        } catch (e) {
            console.error(e.message);
        }
    }
}