import TaskRunner from './base/TaskRunner';
// const breakpoints = require('./base/breakpoints');

// breakpoints.regBreakpoint('medium', 768);
// breakpoints.regBreakpoint('big', 1200);

// breakpoints.start();

import './tasks/others';
import './tasks/header';

import './tasks/accordion';
import './tasks/swiper';
// import './tasks/fancybox';
// import './tasks/smoothScroll';
// import './tasks/tippy';
// import './tasks/youtube';
import './tasks/customFocus';
import './tasks/popups';
import './tasks/tabs';
import './tasks/forms';
// import './tasks/rellax';
// import './tasks/nouislider';
// import './tasks/fslightbox';
// import './tasks/pseudoselect';
import './tasks/scrollAnimations';

import './tasks/body';

document.addEventListener('DOMContentLoaded', () => {
    TaskRunner.run();
});