/**
 * Модуль для проверки наличия какого-то блока во вьюпорте и триггера события с него
 */

export const IN_VIEWPORT = 'inViewport';
// export const OUT_VIEWPORT = 'outViewport';

const options = {
    root: null,
    rootMargin: '-20% 0px',
    threshold: 0.01
};

let observer;

const observeCallback = (entries, obs) => {
    let iterator = 0;

    entries.filter(
        entry => entry.isIntersecting
    ).forEach(entry => {
        entry.target.dispatchEvent(new CustomEvent(IN_VIEWPORT, {'detail': iterator++}));
    });
};

export const isObserverSupporting = () => !(!'IntersectionObserver' in window &&
    !'IntersectionObserverEntry' in window &&
    !'intersectionRatio' in window.IntersectionObserverEntry.prototype);

export const watch = (element) => {
    if (typeof observer === 'undefined') {
        if (!isObserverSupporting) {
            element.dispatchEvent(new Event(IN_VIEWPORT));
            return;
        }

        observer = new IntersectionObserver(observeCallback, options);
    }

    observer.observe(element);
};

export const unWatch = (element) => {
    if (observer) observer.unobserve(element);
}