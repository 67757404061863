import Accordion from '../components/Accordion';
import $wrap from '../base/jqWrap';
import TaskRunner from '../base/TaskRunner';
import LiveEvent from '../base/LiveEvent';

const defaults = {
    multiple: true,
    selfClose: true,
    collapseChilds: true
};

TaskRunner.add(() => {
    if (typeof $ !== 'undefined') {
        $.fn.accordion = function (...params) {
            return $wrap(this, Accordion, 'accordion', params);
        };
    }

    window.Accordion = Accordion;

    LiveEvent.on('click', '.accordion-header', (e) => {
        let root = e.target.closest('.accordion-root');

        if (root && root.accordion != undefined) {
            root.accordion.toggle();
        }
    });

    document.querySelectorAll('.accordion').forEach(acc => {
        new Accordion(acc, defaults);
    });
});