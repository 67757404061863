import animItems from '../data/animItems';
import TaskRunner from '../base/TaskRunner';
import * as viewportWatcher from '../components/viewportWatcher';

const defaults = {
    anim: 'animate__fadeIn',
    delayBase: 0,
    delayStep: 0,
    itemClass: 'anim-item',
    parentClass: 'anim-parent',
    classWhenViewport: 'animate__animated'
};

const onViewportHandler = (e) => {
    if(e.target['data-once']){
        viewportWatcher.unWatch(e.target);
    }

    e.target.classList.add('anim-inViewport', `anim-order${e.detail}`);
    e.target.querySelectorAll('.' + defaults.itemClass).forEach(item => {
        item.classList.add(defaults.classWhenViewport);
    });
};

TaskRunner.add(() => {
    animItems.forEach(itm => {
        document.querySelectorAll(itm.selector).forEach(targ => {
            let watchingTarget = targ;

            if (itm.targets != undefined && itm.targets.length > 0) {
                targ.classList.add(defaults.parentClass);

                itm.targets.forEach(subtarg => {
                    let subtargSelector;
                    let anim;
                    let delayBase;
                    let delayStep;

                    switch (typeof subtarg) {
                        case "object":
                            subtargSelector = subtarg.selector;
                            anim = subtarg.anim || defaults.anim;
                            delayBase = subtarg.delayBase || defaults.delayBase;
                            delayStep = subtarg.delayStep || defaults.delayStep;
                            break;
                        case "string":
                            subtargSelector = subtarg;
                            anim = defaults.anim;
                            delayBase = defaults.delayBase;
                            delayStep = defaults.delayStep;
                            break;
                        default: return;
                    }

                    targ.querySelectorAll(subtargSelector).forEach(subtargElement => {
                        subtargElement.classList.add(defaults.itemClass, anim);
                        subtargElement.style['animation-delay'] = `${delayBase}s`;
                        delayBase += delayStep;
                    });
                });
            }

            // if(itm.createTrigger){

            // }

            watchingTarget['data-once'] = itm.once;
            watchingTarget.addEventListener(viewportWatcher.IN_VIEWPORT, onViewportHandler, { once: itm.once });
            viewportWatcher.watch(watchingTarget);
        });
    });
});